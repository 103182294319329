var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var SidebarItem = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, icon = _a.icon, title = _a.title, onClick = _a.onClick, _c = _a.selected, selected = _c === void 0 ? false : _c, _d = _a.open, open = _d === void 0 ? true : _d;
    return (_jsxs("div", __assign({ className: 'flex space-x-4 py-3 md:pl-8 border-r-4 border-transparent hover:bg-blue-100 hover:cursor-pointer' +
            (selected ? ' border-blue-600' : '') +
            ' ' +
            className, onClick: onClick }, { children: [icon, open && _jsx("p", { children: title }, void 0)] }), void 0));
};
