import { useHistory } from 'react-router-dom'

// import { Dropdown, Menu } from 'antd'

import {
  DashboardLayout,
  SidebarItem,
  ReportIcon,
  // ChevronDownIcon,
} from '@alpha/components'
import { dashboardNavGraphItems } from '../navGraph/DashboardNavGraphItem'
// import { logout } from '@alpha/core'

// const AccountMenu = () => {
//   const history = useHistory()

//   const handleLogoutBtn = useCallback(async () => {
//     await logout()
//     history.push('/')
//   }, [history])

//   return (
//     <Menu>
//       <Menu.Item onClick={handleLogoutBtn}>Sign Out</Menu.Item>
//     </Menu>
//   )
// }

const dummyOnClick = (e) => {
  e.preventDefault()
  console.log(e)
}

const navGraphItemIcon = (key: string) => {
  switch (key) {
    case 'reports':
      return ReportIcon
    default:
      return ReportIcon
  }
}

const SidebarMenu = ({ selectedMenu = 1, open = true }) => {
  const history = useHistory()
  return (
    <>
      {dashboardNavGraphItems
        .filter((item) => item.tabIndex)
        .map((item) => (
          <SidebarItem
            key={item.key}
            selected={selectedMenu === item.tabIndex}
            onClick={() => history.push(`/${item.key}`)}
            icon={navGraphItemIcon(item.key)}
            title={open ? item.title : null}
          />
        ))}
    </>
  )
}

export const Dashboard = ({
  navbar,
  children,
  isOpen = true,
  setIsOpen,
  selectedMenu = 1,
  loading = false,
}) => {
  return (
    <DashboardLayout
      sidebar={
        !loading && <SidebarMenu open={isOpen} selectedMenu={selectedMenu} />
      }
      navbar={navbar}
      loading={loading}
      onSettingsClick={dummyOnClick}
      account={
        // <div className="flex space-x-4 items-center">
        //   <Dropdown overlay={<AccountMenu />}>
        //     <div className="w-12 h-12 bg-white dark:bg-gray-900 rounded-full flex items-center justify-center  hover:bg-gray-50 dark:hover:bg-gray-800">
        //       {ChevronDownIcon}
        //     </div>
        //   </Dropdown>
        // </div>
        null
      }
      open={isOpen}
      setIsOpen={setIsOpen}
    >
      {children}
    </DashboardLayout>
  )
}
