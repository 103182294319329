import { SchoolCategory } from './generated/graphql';
export * from './generated/graphql';
export * from './auth/AuthProvider';
export * from './routing/PrivateRoute';
export * from './routing/PublicRoute';
export * from './services/api/ApolloClient';
export function calcShuttleRunGainOxygen(x) {
    if (x < 0) {
        return 0;
    }
    var seq = [2, 3, 2, 2];
    var n = 262;
    for (var i = 2; i <= x; i++) {
        n += seq[(i - 1) % seq.length];
    }
    return n / 10;
}
export function makeUnitName(scoreType) {
    switch (scoreType) {
        case 'grip':
            return '(kg)';
        case 'sitUps':
            return '(回)';
        case 'bending':
            return '(cm)';
        case 'sideJump':
            return '(点)';
        case 'shuttleRun':
            return '(回)';
        case 'sprintRun':
            return '(秒)';
        case 'standingJump':
            return '(cm)';
        case 'handballThrow':
            return '(m)';
        case 'totalScore':
            return '(点)';
    }
    return '';
}
export function makeGradeName(c, grade) {
    switch (c) {
        case SchoolCategory.ElementarySchool:
            return "\u5C0F" + grade;
        case SchoolCategory.JuniorHighSchool:
            return "\u4E2D" + grade;
        case SchoolCategory.HighSchool:
            return "\u9AD8" + grade;
    }
    return '';
}
export function getSchoolYear(date) {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    return month < 4 ? year - 1 : year;
}
export var hiraganaPattern = /[\u{3041}-\u{3093}]+/mu;
export var hiraganaMessage = 'ひらがなで入力して下さい';
