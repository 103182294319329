var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Container } from '@sergeimeza/uikit-react';
export var OnboardingLayout = function (_a) {
    var children = _a.children;
    return (_jsx("div", __assign({ className: "min-h-screen bg-gray-100" }, { children: _jsx(Container, __assign({ className: "py-10" }, { children: children }), void 0) }), void 0));
};
