import { useEffect } from 'react'

import { Dashboard } from '../layout/Dashboard'
import { Card } from '@alpha/components'
import { Card as ACard } from 'antd'
import TestRankBarChart from '../graph/TestRankBarChart'
import {
  SchoolCategory,
  useReportTestRankTargetSummaryLazyQuery,
} from '@alpha/core'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { getCity } from '../../data/data'

type Props = {
  prefectureCode: number
  cityCode: number
  forPrinting: boolean
}

const ReportTestRankTargetGraph = ({
  prefectureCode,
  cityCode,
  forPrinting,
}: Props) => {
  const [getReport, { data, loading }] =
    useReportTestRankTargetSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (prefectureCode === 0) return
    getReport({
      variables: {
        input: {
          prefectureCode,
          cityCode,
        },
      },
    })
  }, [prefectureCode, cityCode, getReport])

  if (loading) {
    return <Loading />
  }

  if (!data?.reportTestRankTargetSummary?.data) {
    return <NoData />
  }

  let reportData = data.reportTestRankTargetSummary.data

  let reports = reportData.reports?.slice().sort((a, b) => b.year! - a.year!)

  if (!reports) {
    return <NoData />
  }

  return (
    <>
      <ACard title="全体の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={reports}
          targetType={null}
          forPrinting={forPrinting}
        />
      </ACard>
      <ACard title="小学校の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={reports}
          targetType={SchoolCategory.ElementarySchool}
          forPrinting={forPrinting}
        />
      </ACard>
      <ACard title="中学校の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={reports}
          targetType={SchoolCategory.JuniorHighSchool}
          forPrinting={forPrinting}
        />
      </ACard>
      {/* <ACard title="高校の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={reports}
          targetType={SchoolCategory.HighSchool}
          forPrinting={forPrinting}
        />
      </ACard> */}
    </>
  )
}

const ReportTestRankTargetGraphPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const city = getCity()
  const prefectureCode = 8

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">総合評価段階別人数の割合の推移(グラフ)</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div style={{ width: 600 }}>
            <ReportTestRankTargetGraph
              prefectureCode={prefectureCode}
              cityCode={city.code}
              forPrinting={false}
            />
          </div>

          <ul>
            <li>
              ※
              小学校の項目は、小学校・義務教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              中学校の項目は、中学校・義務教育学校（後期課程）・中等教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              高等学校の項目は、高等学校・中等教育学校（後期課程）のデータを含みます。
            </li>
            <li>
              ※
              実施人数には未実施種目がある児童生徒も含みます。
            </li>
          </ul>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>茨城県児{city.name}童生徒の体力・運動能力調査結果</div>
        <div>総合評価段階別人数の割合の推移（グラフ）</div>
        <ReportTestRankTargetGraph
          prefectureCode={prefectureCode}
          cityCode={city.code}
          forPrinting
        />
      </div>
    </Dashboard>
  )
}

export default ReportTestRankTargetGraphPage
