var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { usePrefecturesLazyQuery } from '@alpha/core';
import { useEffect } from 'react';
import { Select } from 'antd';
var Option = Select.Option;
export var JapanPrefectureSelect = function (_a) {
    var _b, _c;
    var areaId = _a.areaId, value = _a.value, onChange = _a.onChange;
    var _d = usePrefecturesLazyQuery({
        fetchPolicy: 'cache-first',
    }), getPrefectures = _d[0], prefectureData = _d[1].data;
    useEffect(function () {
        getPrefectures({ variables: { input: { areaId: areaId } } });
        onChange(0);
    }, [areaId, getPrefectures, onChange]);
    return (_jsxs(Select, __assign({ className: "w-32", value: value, onChange: onChange }, { children: [_jsx(Option, __assign({ value: 0 }, { children: "\u90FD\u9053\u5E9C\u770C" }), void 0), _jsx(_Fragment, { children: (_c = (_b = prefectureData === null || prefectureData === void 0 ? void 0 : prefectureData.prefectures) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.map(function (v) { return (_jsx(Option, __assign({ value: v.code }, { children: v.name }), v._id)); }) }, void 0)] }), void 0));
};
