var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Anchor } from '@sergeimeza/uikit-react';
var AlphaLogo = function () { return (_jsxs("div", __assign({ className: "flex items-center justify-center" }, { children: [_jsx("img", { className: "w-12 h-12", src: "https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/logo.png", alt: "logo" }, void 0), _jsx("div", __assign({ className: "ml-2 text-gray-900 font-semibold text-3xl" }, { children: _jsx("span", { children: "Alpha" }, void 0) }), void 0)] }), void 0)); };
var ISMSLogo = function () { return (_jsx("img", { className: "w-64", src: "https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/isms.jpg", alt: "isms" }, void 0)); };
var SignInLayoutComponent = function (_a) {
    var children = _a.children;
    var layout = (_jsxs("div", __assign({ className: "min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8" }, { children: [_jsx("div", __assign({ className: "mt-8 sm:mx-auto sm:w-full sm:max-w-md" }, { children: _jsx("div", __assign({ className: "bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10" }, { children: children }), void 0) }), void 0), _jsx("div", __assign({ className: "mt-8 flex flex-col content-center border-b border-gray-400 w-80 mx-auto mb-4" }, { children: _jsxs("p", __assign({ className: "text-gray-700 font-bold text-center" }, { children: ["Copyright \u00A9 Pestalozzi Technology ", new Date().getFullYear()] }), void 0) }), void 0), _jsxs("address", __assign({ className: "text-center" }, { children: [_jsx("h4", __assign({ className: "text-gray-900 font-bold" }, { children: "\u3010\u554F\u3044\u5408\u308F\u305B\u5148\u3011" }), void 0), _jsx(Anchor, __assign({ className: "font-bold", href: "mailto:alpha@pestalozzi-tech.com" }, { children: "alpha@pestalozzi-tech.com" }), void 0), _jsx("p", __assign({ className: "mt-2 text-sm" }, { children: "\u62C5\u5F53\uFF1AAlpha\u65B0\u4F53\u529B\u30C6\u30B9\u30C8\u306E\u6E2C\u5B9A\u30FB\u96C6\u8A08\u30B7\u30B9\u30C6\u30E0\u62C5\u5F53" }), void 0)] }), void 0), _jsx("div", __assign({ className: "mx-auto" }, { children: _jsx(ISMSLogo, {}, void 0) }), void 0)] }), void 0));
    return layout;
};
export var SignInLayout = Object.assign(SignInLayoutComponent, {
    AlphaLogo: AlphaLogo,
    ISMSLogo: ISMSLogo,
});
export default SignInLayout;
