import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { firebaseConfig } from './firebase-config';
export function InitializeFirebase() {
    var app = firebase.initializeApp(firebaseConfig);
    var auth = app.auth();
    var database = app.database();
    return {
        app: app,
        auth: auth,
        database: database,
    };
}
