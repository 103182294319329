import { ReportScoreByScoreType } from '../pages/ReportCrossAnalysisGraphPage'
import { makeGradeName, makeUnitName, SchoolCategory, UserGender } from '@alpha/core'
import { Card } from 'antd'
import { scoreTypes } from '../pages/ReportAverageScoreGraphPage'
import Title from 'antd/es/typography/Title'
import {
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Symbols,
} from 'recharts'
import { SymbolType } from 'recharts/types/util/types'

type Props = {
  data: ReportScoreByScoreType
  visibleCategories: SchoolCategory[]
  forPrinting: boolean
}

const colors = ['#aa0000', '#aaaa00', '#008000', '#0000aa']

const legendTypes: SymbolType[] = ['square', 'cross', 'diamond', 'triangle']

const CrossAnalysisLineChart = ({
  data,
  visibleCategories,
  forPrinting,
}: Props) => {
  const {scoreType} = data
  const scoreTypeName = scoreTypes[scoreType]
  return (
    <Card title={scoreTypeName} style={{ pageBreakAfter: 'always' }}>
      {data.genders.map((d, index) => {
        const genderName = d.gender === UserGender.Male ? '男子' : '女子'
        const dataKeys = new Set<string>()

        let chartData = d.grades.map((grade) => {
          if (visibleCategories.indexOf(grade.schoolCategory) === -1) {
            return null
          }
          const gradeName = makeGradeName(grade.schoolCategory, grade.grade)
          const cd = {
            name: gradeName,
          }
          grade.answers.forEach((answer) => {
            dataKeys.add(answer.answer)
            cd[answer.answer] = answer.score
          })
          return cd
        })
        chartData = chartData.filter((v) => v)
        const keys = Array.from(dataKeys)

        const lineCharProps = {
          data: chartData,
          width: forPrinting ? 450 : 0,
          height: forPrinting ? 370 : 0,
        }
        const legendWrapperStyle = forPrinting ? { top: -36 } : {}

        const lineChart = (
          <LineChart {...lineCharProps}>
            <Legend wrapperStyle={legendWrapperStyle} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" type="category" />
            <YAxis
              tickCount={20}
              domain={[
                (dataMin) => Math.round(dataMin - 3),
                (dataMax) => Math.round(dataMax + 3),
              ]}
              reversed={scoreType === 'sprintRun'}
              label={{ value: makeUnitName(scoreType), angle: -90, position: 'insideLeft' }}
            />
            <Tooltip />
            {keys.map((key, idx) => (
              <Line
                key={key}
                dataKey={key}
                stroke={colors[idx]}
                isAnimationActive={false}
                legendType={legendTypes[idx % legendTypes.length]}
                dot={
                  <Symbols
                    type={legendTypes[idx % legendTypes.length]}
                    fillOpacity={idx >= legendTypes.length ? 1 : 0.2}
                    fill={colors[idx]}
                  />
                }
              />
            ))}
          </LineChart>
        )

        if (forPrinting) {
          return (
            <div className="flex flex-col items-center space-y-8">
              <Title level={5}>{genderName}</Title>
              {lineChart}
            </div>
          )
        }

        return (
          <Card.Grid
            key={`g${index}`}
            style={{ width: '50%', textAlign: 'center' }}
            hoverable={false}
          >
            <Title level={5}>{genderName}</Title>
            <ResponsiveContainer width="100%" height={360}>
              {lineChart}
            </ResponsiveContainer>
          </Card.Grid>
        )
      })}
    </Card>
  )
}

export default CrossAnalysisLineChart
