import { useCallback, useState } from 'react'

import { SignInLayout } from '@alpha/components'
import { signIn } from '@alpha/core'

import { message, Divider } from 'antd'

import { EmailSent } from '../shared/EmailSent'
import { getCity } from '../../data/data'

const SignInPage = () => {
  // chikusei: 82279
  // hitachiota: 82121
  // ishioka: 82058
  // kamisu: 82325
  // kashima: 82228
  // kawachi: 84476
  // koga: 82040
  // omitama: 82368
  // sakuragawa: 82317
  // takahagi: 82147
  // tokai: 83411

  // const cityNameToCode = {
  //   chikusei: 82279,
  //   hitachiota: 82121,
  //   ishioka: 82058,
  //   kamisu: 82325,
  //   kashima: 82228,
  //   kawachi: 84476,
  //   koga: 82040,
  //   omitama: 82368,
  //   sakuragawa: 82317,
  //   takahagi: 82147,
  //   tokai: 83411,
  // } as const

  /*
   82147 高萩市 たかはぎし
   83101 城里町 しろさとまち
   82112 常総市 じょうそうし
   82155 北茨城市 きたいばらきし
   82244 守谷市 もりやし
   82287 坂東市 ばんどうし
   82368 小美玉市 おみたまし
   83097 大洗町 おおあらいまち
   83640 大子町 だいごまち
   84433 阿見町 あみまち
   82295 稲敷市 いなしきし
   83020 茨城町 いばらきまち
   83411 東海村 とうかいむら
   82201 つくば市 つくばし
   82261 那珂市 なかし
   82341 鉾田市 ほこたし
   82015 水戸市 みとし
   82252 常陸大宮市 ひたちおおみやし
   82350 つくばみらい市 つくばみらいし
   82104 下妻市 しもつまし
   82333 行方市 なめがたし
   85464 境町 さかいまち
   82074 結城市 ゆうきし
   82163 笠間市 かさまし
   82171 取手市 とりでし
   82058 石岡市 いしおかし
   82309 かすみがうら市 かすみがうらし
   82023 日立市 ひたちし
   82228 鹿嶋市 かしまし
   82236 潮来市 いたこし
   85219 八千代町 やちよまち
   82040 古河市 こがし
   82198 牛久市 うしくし
   82279 筑西市 ちくせいし
   82325 神栖市 かみすし
   85642 利根町 とねまち
   82082 龍ケ崎市 りゅうがさきし
   85421 五霞町 ごかまち
   82210 ひたちなか市 ひたちなかし
   84476 河内町 かわちまち
   82031 土浦市 つちうらし
   84425 美浦村 みほむら
   82121 常陸太田市 ひたちおおたし
   82317 桜川市 さくらがわし
  */
  const city = getCity()

  const [address, setAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const handleChangeAddress = (e) => setAddress(e.target.value)

  const handleSignInBtn = useCallback(async () => {
    if (
      [
        'inoue@pestalozzi-tech.com',
        'phamvantien142@gmail.com',
        'me@transa.ng',
        'atsumi@pestalozzi-tech.com',
        'sergei@pestalozzi-tech.com',
        'fujiwara@pestalozzi-tech.com',
        'ikuto@pestalozzi-tech.com',
        'locluuvinhbk@gmail.com',

        'gakko@city.kamisu.ibaraki.jp',
        'k-soumu@city.takahagi.lg.jp',
        'koga_shidou@koga.ed.jp',
        'gakumu@city.chikusei.lg.jp',
        'sidourm@city.chikusei.lg.jp',
        // 'hiyama.teruhisa@mail.ibk.ed.jp',
        'tamura@pestalozzi-tech.com',
        'koji.shinohara@city.omitama.lg.jp',

        // https://docs.google.com/spreadsheets/d/1QL-oxqPqKM279aP0gpkOdsgdRLDC5nYkh0xIc4gqbGQ/edit#gid=0
        'maruyama.akinori@mail.ibk.ed.jp',
        'hiyama.teruhisa@mail.ibk.ed.jp',
        'to-ohtsuki@city.takahagi.lg.jp',
        // 'koji.shinohara@city.omitama.lg.jp',
        'kyouikusoumu2@city.ibaraki-kashima.lg.jp',
        'gakumu2@city.hitachiota.lg.jp',
        'kyouikusidou_s@city.sakuragawa.lg.jp',
        'u4614@city.utsunomiya.tochigi.jp',
        'kyouiku@vill.tokai.ibaraki.jp',
        'you-nabatame@city.ishioka.lg.jp',

        // second column
        'ootuka.yuuzou@post.ibk.ed.jp',
        'sa-hoshi@city.takahagi.lg.jp',
        'miki.komatsuzaki@city.omitama.lg.jp',
        'kyoui@town.ibaraki-kawachi.lg.jp',

        'gakko@city.omitama.lg.jp',
        '231@brd.ibk.ed.jp',
        'sidourm@city.chikusei.lg.jp',

        // https://pes-techs.slack.com/archives/C037YG11W3F/p1669009543113509
        '227@brd.ibk.ed.jp',
        // https://pes-techs.slack.com/archives/C037YG11W3F/p1669098700929709
        'shidou@city.ibaraki-koga.lg.jp',

        '550101@sch.ibk.ed.jp',
        '550102@sch.ibk.ed.jp',
        '550103@sch.ibk.ed.jp',
        '550104@sch.ibk.ed.jp',
        '550105@sch.ibk.ed.jp',
        '550106@sch.ibk.ed.jp',
        '550107@sch.ibk.ed.jp',
        '551501@sch.ibk.ed.jp',
        '551502@sch.ibk.ed.jp',
        '551503@sch.ibk.ed.jp',
        '551504@sch.ibk.ed.jp',
        '551505@sch.ibk.ed.jp',
        '551506@sch.ibk.ed.jp',
        '551507@sch.ibk.ed.jp',
        '551508@sch.ibk.ed.jp',
        '551509@sch.ibk.ed.jp',
        '551510@sch.ibk.ed.jp',
        '551701@sch.ibk.ed.jp',
        '551702@sch.ibk.ed.jp',
        '551703@sch.ibk.ed.jp',
        '551704@sch.ibk.ed.jp',
        '551705@sch.ibk.ed.jp',
        '551706@sch.ibk.ed.jp',
        '650101@sch.ibk.ed.jp',
        '650102@sch.ibk.ed.jp',
        '650103@sch.ibk.ed.jp',
        '651501@sch.ibk.ed.jp',
        '651502@sch.ibk.ed.jp',
        '651503@sch.ibk.ed.jp',
        '651701@sch.ibk.ed.jp',
        '651702@sch.ibk.ed.jp',
        '651703@sch.ibk.ed.jp',
        'koga_shidou@koga.ed.jp',
      ].includes(address) === false
    ) {
      message.warning(
        '本メールアドレスが登録されていません。管理者にお問い合わせください。',
      )
    } else {
      setIsLoading(true)
      const { protocol, host } = window.location
      const url = `${protocol}//${host}/`
      await signIn(address, url)
      setIsSent(true)
    }
  }, [address])

  return (
    <SignInLayout>
      <div className="text-center w-full py-4 underline text-blue-500">
        <a
          href="https://ibaraki.upload.pestalozzi-tech.com/"
          target="_blank"
          rel="noreferrer"
        >
          茨城県{city.name}内の各自治体集計フォーマットのアップロードはこちら
        </a>
      </div>
      <div className="flex flex-col space-y-2">
        {isSent ? (
          <div className="flex flex-col items-center space-y-12">
            <EmailSent />
            <div className="text-center">
              <h1 className="text-xl font-semibold">
                ログインメールを送信しました
              </h1>
              <h5 className="text-gray-400 font-bold">
                ※ メールに送信したリンクをこちらのブラウザーで開いてください。
              </h5>
            </div>
          </div>
        ) : (
          <>
            <h5 className="font-bold text-lg">
              茨城県{city.name}児童生徒の体力・運動能力調査
              <br />
              集計システム「Alpha」へようこそ
            </h5>
            <p>メールアドレス</p>
            <input
              type="email"
              placeholder="メールアドレス"
              className="bg-gray-50 p-2 rounded border border-gray-200"
              value={address}
              onChange={handleChangeAddress}
            />
            <div className="pt-4">
              <button
                className="bg-blue-500 hover:bg-blue-400 focus:bg-blue-400　text-white font-semibold py-2 p px-4 mb-4 rounded"
                onClick={handleSignInBtn}
                disabled={isLoading}
                style={{ color: 'white' }}
              >
                メールにサインイン情報を送る
              </button>
            </div>
            <div className="flex">
              <div className="text-red-500 p-2 border-red-500 border mb-4">
                HTML形式でメールが届きます。HTML形式メールを許可してください。
                <br />
                設定方法：
                <a
                  className="underline font-semibold"
                  href="https://www.crie.co.jp/chokotech/detail/180/"
                  target="_blank"
                >
                  https://www.crie.co.jp/chokotech/detail/180/
                </a>
                <br />
                <small>*外部リンクとなります</small>
              </div>
            </div>
            <Divider />
            <div className="pt-4">
              <p>
                続行することで、Pestalozzi Technologyの
                <a
                  className="text-blue-700 underline font-semibold"
                  href="https://pestalozzi-tech.com/alpha-terms/"
                >
                  サービス利用規約
                </a>
                および、
                <a
                  className="text-blue-700 underline font-semibold"
                  href="https://pestalozzi-tech.com/privacy-policy/"
                >
                  個人情報保護ポリシー
                </a>
                に同意します。
              </p>
            </div>
          </>
        )}
      </div>
    </SignInLayout>
  )
}

export default SignInPage
