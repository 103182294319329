import { ReportAverageScoreByGender } from '../pages/ReportAverageScoreGraphPage'
import { makeGradeName, makeUnitName, SchoolCategory } from '@alpha/core'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Symbols,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { SymbolType } from 'recharts/types/util/types'

const makeYearName = (y: number): string => {
  if (y >= 2019) {
    return `R${y - 2018}`
  }
  return `H${y - 1988}`
}

const colors = [
  '#000000',
  '#ff0000',
  '#00aa00',
  '#0000ff',
  '#ff00ff',
  '#00aaaa',
  '#888888',
  '#880000',
  '#006600',
  '#000088',
  '#880088',
  '#006666',
]

const legendTypes: SymbolType[] = [
  'square',
  'cross',
  'diamond',
  'star',
  'triangle',
  'wye',
]

type Props = {
  data: ReportAverageScoreByGender
  visibleCategories: SchoolCategory[]
  forPrinting: boolean
  scoreType: string
}

const ScoreLineChart = ({ data, visibleCategories, forPrinting, scoreType }: Props) => {
  if (!data) return null

  const dataKeys = new Set<string>()
  const chartData = data.years.map((y) => {
    const d = {
      name: makeYearName(y.year),
    }
    y.grades.forEach((grade) => {
      if (visibleCategories.indexOf(grade.schoolCategory) === -1) {
        return
      }
      const dataKey = makeGradeName(grade.schoolCategory, grade.grade)
      dataKeys.add(dataKey)
      d[dataKey] = grade.score?.toFixed(2)
    })
    return d
  })
  const keys = Array.from(dataKeys)

  const lineCharProps = {
    data: chartData,
    width: forPrinting ? 400 : 0,
    height: forPrinting ? 400 : 0,
  }
  const legendWrapperStyle = forPrinting ? { top: -36 } : { top: -16 }
  const yAxisBuffer = scoreType === 'sprintRun' ? 0.5 : 2

  const lineChart = (
    <LineChart {...lineCharProps}>
      <Legend verticalAlign="top" wrapperStyle={legendWrapperStyle} />
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" type="category" />
      <YAxis
        tickCount={20}
        domain={[
          (dataMin) => Math.round(dataMin - yAxisBuffer),
          (dataMax) => Math.round(dataMax + yAxisBuffer),
        ]}
        label={{ value: makeUnitName(scoreType), angle: -90, position: 'insideLeft' }}
      />
      <Tooltip />
      {keys.map((key, idx) => (
        <Line
          key={key}
          dataKey={key}
          stroke={colors[idx]}
          isAnimationActive={false}
          legendType={legendTypes[idx % legendTypes.length]}
          dot={
            <Symbols
              type={legendTypes[idx % legendTypes.length]}
              fillOpacity={idx >= legendTypes.length ? 1 : 0.2}
              fill={colors[idx]}
            />
          }
        />
      ))}
    </LineChart>
  )

  if (forPrinting) {
    return lineChart
  }

  return (
    <ResponsiveContainer width="100%" height={680}>
      {lineChart}
    </ResponsiveContainer>
  )
}

export default ScoreLineChart
