import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import AnimationData from './loading.json'

export const Loading = () => {
  const container = useRef<any>(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: AnimationData,
    })
  }, [])

  return (
    <div
      className="container"
      ref={container}
      style={{ width: 250, height: 250 }}
    />
  )
}
