var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Redirect, Route } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../auth/AuthProvider';
export var PrivateRoute = function (_a) {
    var Component = _a.component, graphItem = _a.graphItem, _b = _a.isOpen, isOpen = _b === void 0 ? true : _b, _c = _a.setIsOpen, setIsOpen = _c === void 0 ? {} : _c, rest = __rest(_a, ["component", "graphItem", "isOpen", "setIsOpen"]);
    var auth = useContext(AuthContext);
    var isAuthChecked = auth.isAuthChecked, currentUser = auth.currentUser, isTokenChecked = auth.isTokenChecked, isRegistered = auth.isRegistered;
    return (_jsx(Route, __assign({}, rest, { render: function (props) {
            if (!isAuthChecked) {
                return _jsx(_Fragment, {}, void 0);
            }
            if (currentUser) {
                // alpha のユーザー登録がまだなら登録ページに飛ばす
                if (isTokenChecked && !isRegistered && rest.path !== '/register') {
                    return (_jsx(Redirect, { to: { pathname: '/register', state: { from: props.location } } }, void 0));
                }
                return (_jsx(Component, __assign({ graphItem: graphItem, isOpen: isOpen, setIsOpen: setIsOpen }, props), void 0));
            }
            else {
                return (_jsx(Redirect, { to: { pathname: '/', state: { from: props.location } } }, void 0));
            }
        } }), void 0));
};
