import ibaraki_city from './ibaraki_city.json'

export const getCity = () => {
  let default_city = ibaraki_city[0]

  let city_code = +(process.env.REACT_APP_CITY_CODE ?? '')

  if (!city_code || isNaN(city_code)) {
    return default_city
  }

  let city = ibaraki_city.find((v) => v.code === city_code)

  return city ?? default_city
}
