var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../../auth/AuthProvider';
import OfflineLink from 'apollo-link-offline';
// import { persistCache } from 'apollo-cache-persist'
var cache = new InMemoryCache({});
var generateUuid = function () {
    var chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('');
    for (var i = 0, len = chars.length; i < len; i++) {
        switch (chars[i]) {
            case 'x':
                chars[i] = Math.floor(Math.random() * 16).toString(16);
                break;
            case 'y':
                chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                break;
        }
    }
    return chars.join('');
};
var authLink = setContext(function (_, _a) {
    var _b;
    var headers = _a.headers;
    var uuid = generateUuid();
    var authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
    return {
        headers: __assign(__assign({}, headers), (_b = { 'session-id': uuid }, _b[LOCAL_STORAGE_AUTH_TOKEN_KEY] = "Bearer " + authToken, _b)),
    };
});
var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
    }
    LocalStorage.setItem = function (key, value) {
        var data = !value ? '' : value;
        window.localStorage.setItem(key, data);
    };
    LocalStorage.getItem = function (key) {
        var item = window.localStorage.getItem(key);
        return Promise.resolve(item);
    };
    LocalStorage.removeItem = function (key) {
        window.localStorage.removeItem(key);
    };
    return LocalStorage;
}());
export var offlineLink = new OfflineLink({
    storage: LocalStorage,
});
export function getApolloClient(clientType) {
    return __awaiter(this, void 0, void 0, function () {
        var httpLink, client;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    httpLink = createHttpLink({
                        uri: process.env.REACT_APP_API_URL,
                        headers: {
                            client: clientType,
                        },
                    });
                    client = new ApolloClient({
                        link: ApolloLink.from([offlineLink, authLink, httpLink]),
                        cache: cache,
                        defaultOptions: {
                            query: {
                                fetchPolicy: 'cache-first',
                                errorPolicy: 'all',
                            },
                            mutate: {
                                optimisticResponse: true,
                                errorPolicy: 'all',
                            },
                        },
                    });
                    // await persistCache({
                    //   cache,
                    //   storage: LocalStorage,
                    //   maxSize: false,
                    //   debug: true,
                    // })
                    return [4 /*yield*/, offlineLink.setup(client)];
                case 1:
                    // await persistCache({
                    //   cache,
                    //   storage: LocalStorage,
                    //   maxSize: false,
                    //   debug: true,
                    // })
                    _a.sent();
                    return [2 /*return*/, client];
            }
        });
    });
}
