import { OnboardingLayout } from '@alpha/components'
import { Card } from '@alpha/components'
import { useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Redirect, useHistory } from 'react-router-dom'
import { AuthContext, useRegisterUserMutation } from '@alpha/core'

const errorMsg = '必須項目です'

const RegisterUserPage = () => {
  const { setAccessToken, currentUser, isRegistered } = useContext(AuthContext)
  const history = useHistory()
  const { register, handleSubmit, errors } = useForm()
  const [registerUser, { loading }] = useRegisterUserMutation()

  const onSubmit = useCallback(
    (formData) => {
      if (!currentUser) return
      const variables = {
        input: {
          uid: currentUser.uid,
          attributes: {
            familyName: formData.familyName,
            givenName: formData.givenName,
          },
        },
      }
      registerUser({ variables }).then((res) => {
        const { registerUser } = res.data!
        if (registerUser?.error) {
          alert(registerUser.error)
          return
        }
        if (registerUser?.token) {
          setAccessToken(registerUser.token)
          history.push('/home')
        }
      })
    },
    [currentUser, registerUser, history, setAccessToken],
  )

  if (isRegistered) {
    return <Redirect to="/home" />
  }

  return (
    <OnboardingLayout>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-1 gap-6 mx-4 md:mx-16 my-8 md:grid-cols-2 lg:grid-cols-3 lg:max-w-screen-lg lg:mx-auto"
        >
          <label className="block md:col-start-1 md:col-span-1">
            <div className="dark:text-white">姓</div>
            <div className="theme-error">
              {errors.familyName && errors.familyName.message}
            </div>
            <input
              type="text"
              name="familyName"
              className="theme-input"
              ref={register({ required: errorMsg })}
              disabled={loading}
            />
          </label>
          <label className="block md:col-start-1 md:col-span-1">
            <div className="dark:text-white">名</div>
            <div className="theme-error">
              {errors.givenName && errors.givenName.message}
            </div>
            <input
              type="text"
              name="givenName"
              className="theme-input"
              ref={register({ required: errorMsg })}
              disabled={loading}
            />
          </label>
          <input
            type="submit"
            value="送信"
            className="md:col-start-1 md:col-span-1 bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 dark:text-white font-semibold py-2 p px-4 mb-4 rounded"
            disabled={loading}
          />
        </form>
      </Card>
    </OnboardingLayout>
  )
}

export default RegisterUserPage
